console.log("COMMON.JS");
const { height } =window.innerHeight;

export default {
  COLORS: {
    primary: "#001F2D",
    secondary: "#4D626C",
    white: "#FFFFFF",
    border: "#F3F4F8",
    grey5: "#000000",
    grey4: "#444444",
    grey3: "#888888",
    grey2: "#CCCCCC",
    grey1: "#EEEEEE",
    color5: "#2B1717",
    color4: "#4D2B2B",
    color3: "#A1806E",
    color2: "#C4A899",
    color1: "#F7E8E1",
    error: "#FF3737",
    warning: "#FFA51E",
    success: "#4ABA63",
    transparent: "transparent",
  },
  FONTS: {
    Regular:  "Gotham-Regular",
    Bold: "Gotham-Bold",
    Medium: "Gotham-Medium",
    Bota: "Bota"
  },
  FONT_SIZES: {
    header_title: 24,
    h1: 24,
    h2: 18,
    p: 15,
    small: 12,
    text_input: 16
  },
  BUTTONS: {
    borderRadius: 30,
    fontSize: 12,
    iconMarginRight: 14,
    primary_background: "color4",
    primary_text_color: "white",
    primary_background_pressed: "color5",
    primary_text_color_pressed: "white",
    primary_background_disabled: "grey3",
    primary_text_color_disabled: "grey2",
    primary_background_loading: "color4",
    primary_text_color_loading: "grey1",
    secondary_background: "red",
    secondary_border_width: 1,
    secondary_border_color: "color2",
    secondary_text_color: "color5",
    secondary_background_pressed: "color2",
    secondary_border_color_pressed: "color2",
    secondary_text_color_pressed: "white",
    secondary_background_disabled: "transparent",
    secondary_border_color_disabled: "color5",
    secondary_text_color_disabled: "color5",
    secondary_background_loading: "transparent",
    secondary_border_color_loading: "color5",
    secondary_text_color_loading: "color5",
    link_background: "white",
    link_background_pressed: "white",
    link_background_disabled: "white",
    link_text_color: "color5",
    link_text_color_pressed: "color4",
    link_text_color_disabled: "grey2",
    link_font_size: 12,
    link_line_height: 17,
    link_font_family: "Medium",
    primary_round_background: "color5",
    primary_round_background_pressed: "color4",
    primary_round_icon_color: "white",
    primary_round_icon_color_pressed: "white",
    secondary_round_background: "color4",
    secondary_round_background_pressed: "color5",
    secondary_round_icon_color: "grey5",
    secondary_round_icon_color_pressed: "grey1",
  },
  HOME: {
    header_with_logo: false,
    bg_img_close_menu_scale: 2.1,
    bg_img_close_menu_scale_small_height: 1.75,
    bg_img_open_menu_scale: 1,
    bg_img_open_menu_scale_small_height: 0.8,
    bg_img_translate_Y_percentage: 0.25,
    bg_img_translate_X_percentage: 0,
    carousel_height: height * 0.55,
    carousel_height_small_screens: 395,
  },
  MENU: {
    background: "color3",
    text_color: "white",
    font_family: "Bota",
    language_submenu_background: "color4",
  },
  GLOBAL_SPACES: {
    paddingHorizontal: "5.7%",
  },
};
  