// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Gotham/Gotham-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Gotham/Gotham-Book.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Gotham/Gotham-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Bota/Bota Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gotham-Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }
  
  @font-face {
    font-family: 'Gotham-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  }
  
  @font-face {
    font-family: 'Gotham-Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  }
  
  @font-face {
    font-family: 'Bota';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___})
  }

body {
    background-color: #fff;
    background-size: cover;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-top: auto;
    position: absolute;
    margin: auto;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#__next {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4CAAiD;EACnD;;EAEA;IACE,6BAA6B;IAC7B,4CAAiD;EACnD;;EAEA;IACE,4BAA4B;IAC5B,4CAAmD;EACrD;;EAEA;IACE,mBAAmB;IACnB;EACF;;AAEF;IACI,sBAAsB;IACtB,sBAAsB;IACtB,uCAAuC;IACvC,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,YAAY;AAChB","sourcesContent":["@font-face {\n    font-family: 'Gotham-Bold';\n    src: url(\"./assets/fonts/Gotham/Gotham-Bold.otf\");\n  }\n  \n  @font-face {\n    font-family: 'Gotham-Regular';\n    src: url(\"./assets/fonts/Gotham/Gotham-Book.otf\");\n  }\n  \n  @font-face {\n    font-family: 'Gotham-Medium';\n    src: url(\"./assets/fonts/Gotham/Gotham-Medium.otf\");\n  }\n  \n  @font-face {\n    font-family: 'Bota';\n    src: url(\"./assets/fonts/Bota/Bota\\ Bold.otf\")\n  }\n\nbody {\n    background-color: #fff;\n    background-size: cover;\n    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);\n    width: 100%;\n    height: 100%;\n    max-width: 500px;\n    margin-top: auto;\n    position: absolute;\n    margin: auto;\n    flex-direction: column;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n}\n\n#__next {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
