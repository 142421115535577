import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";
import UnderLine from "../../components/UnderLine";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout"
import AuthService from "../../services/auth-service";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import SmallParagraph from "../../components/UI/SmallParagraph";
import { useHistory } from "react-router-dom";
import TextInlineWithLinks from "../../components/UI/TextInlineWithLinks";
import Alerts from "../../components/Alerts";
import { noResponseParams, notOkPageParams } from "../../components/Helpers/ResultMessagesHelper";


export default function ForgotPassword1({ navigation, route }) {
  const { t } = useTranslation();
  const history = useHistory();

  const [verificationCode, onChangeVerificationCode] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [counter, setCounter] = useState(90);
  const [startCountdown, setStartCountdown] = useState(typeof withSecondsCounter !== "undefined" ? withSecondsCounter : false);
  const [isAlertVisible, setIsAlertVisible] = useState();

  const email = history.location?.email;
  const successAlert = history.location?.successAlert;

  const [loading, setLoading] = useState(false);

  const toResendParams = {
    pathname: "/ForgotPassword/ResendCode",
    email: email,
    backTo: "/ForgotPassword/Verify",
  };

  const handleAlertVisibility = () => {
    setIsAlertVisible(true);
    setTimeout(() => setIsAlertVisible(false), 5000);
  };

  const alertContainerStyle = {
    opacity: isAlertVisible ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
  };

  useEffect(() => {
    if (successAlert) {
      handleAlertVisibility();
    }
    setStartCountdown(true);
    onChangeVerificationCode("");
    setShowErrorMessage(false);
  }, []);

  useEffect(() => {
    if(!email){
      history.push("/ForgotPassword")
    }
    if (startCountdown) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if (counter === 0) {
        history.push(toResendParams);
        setStartCountdown(false);
        setCounter(90);
      }
      return () => clearInterval(timer);
    }
  }, [counter, startCountdown]);

  async function verify() {
    setLoading(true);
    const res = await AuthService.validateUser({
      email: email,
      code: verificationCode,
    });
    console.log(res);
    if (res.success) {
      history.push({
        pathname: "/ForgotPassword/NewPassword",
        userId: res.result.user._id
      });
      setStartCountdown(false);
      setShowErrorMessage(false);
    } else if (res.errorMessage.expired === true) {
      history.push(toResendParams);
      setStartCountdown(false);
      setCounter(90);
      setShowErrorMessage(false);
    } else if (res.errorMessage.noResponse) {
      history.push("/error", {
        params: noResponseParams,
      });
    } else {
      setShowErrorMessage(true);
    }
    setLoading(false);
  }
  async function resendCode() {
    setLoading(true);
    let res = await AuthService.newValidationCode({
      email: email,
    });
    if (res.success) {
      setStartCountdown(true);
      setCounter(90);
      handleAlertVisibility();
    } else if (res.errorMessage.noResponse) {
      history.push("/error", {
        params: noResponseParams,
      });
    } else {
      history.push("/error", {
        params: notOkPageParams(res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater")),
      });
    }
    setLoading(false);
  }


  return (
    <AppLayout
      headerLabel={t("common:headerForgotPassword")}
      headerBack={true}
      route={route}
      navigation={navigation}
      styleToContainer={{ paddingHorizontal: 0 }}
      style={{ backgroundColor: "#fff" }}
    >
      {isAlertVisible && (
        <div style={alertContainerStyle}>
          <Alerts
            text={t("alerts:resentValidationCode")}
            type="success"
            overlay={false}
          />
        </div>
      )}
      <View
        style={{
          display: "flex",
          flex: 1,
          paddingTop: "5%",
          paddingHorizontal: DefaultTheme.GLOBAL_SPACES.paddingHorizontal,
        }}
      >
        <Text style={styles.verify_txt}>
          <Text>
            {t("authentication:verificationCodeInfoPart1") +
              " " +
              email +
              ". " +
              t("authentication:verificationCodeInfoPart2") +
              " "}
          </Text>
          <Text style={{ fontFamily: DefaultTheme.FONTS.Bold }}>
            {counter + t("authentication:seconds")}
          </Text>
        </Text>
        <UnderLine cellCount={6} onChange={onChangeVerificationCode} />
        {showErrorMessage && (
          <SmallParagraph style={{ color: DefaultTheme.COLORS.error }}>
            {t("errors:validationCodeWrong")}
          </SmallParagraph>
        )}
        <BottomBtnContainer>
          <Button
            text={t("buttons:confirm")}
            element={verificationCode}
            onPress={() => {
              verify();
            }}
            type="PRIMARY"
            loading={loading}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              marginTop: 24,
              maxWidth: "70%",
              flexWrap: "wrap",
            }}
          >
            <TextInlineWithLinks>
              {t("authentication:didntReciveCode")}
            </TextInlineWithLinks>
            <Button
              text={t("buttons:resendHere")}
              onPress={() => {
                resendCode();
              }}
              type="LINK"
              element={loading ? "" : null}
            />
          </View>
        </BottomBtnContainer>
      </View>
    </AppLayout >
  );
}

const styles = StyleSheet.create({
  verify_txt: {
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: DefaultTheme.FONT_SIZES.p,
    lineHeight: 22.5,
    letterSpacing: 0.25,
  },
});

