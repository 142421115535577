import React from "react";
import { createRoot } from "react-dom/client";
import { Router, Route, Switch, Redirect } from "react-router";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { createBrowserHistory } from "history";

import Login from "./pages/LogIn/index";
import UserNotValidated from "./pages/LogIn/UserNotValidated";
import App from "./_app";
import AuthService from "./services/auth-service";
import ForgotPassword from "./pages/ForgotPassword";
import Verify from "./pages/ForgotPassword/Verify";
import NewPassword from "./pages/ForgotPassword/NewPassword";
import CurrentPassword from "./pages/AccountInfo/CurrentPassword";
import ResultMessageScreen from "./pages/ForgotPassword/ResultMessageScreen"
import ReceiptByEmail from "./pages/ReceiptByEmail";
import ReceiptSent from "./pages/ReceiptByEmail/ReceiptSent";
import SignUp from "./pages/SignUp";
import AccountValidation from "./pages/SignUp/AccountValidation";
import ResendCode from "./pages/SignUp/ResendCode";
import AllSet from "./pages/SignUp/AllSet";
import ErrorPage from "./pages/ResultMessageScreen";
import TermsAndConditions from "./pages/HelpAndLegal/TermsAndConditions";
import PrivacyPolicy from "./pages/HelpAndLegal/PrivacyPolicy";
import Purchases from "./pages/Purchases/List"
import PurchasesDetail from "./pages/Purchases/PurchasesDetail";
import ForgotPasswordResendCode from "./pages/ForgotPassword/ResendCode";

function PrivateRoute({ component: Component, ...rest }) {
  const user = AuthService.getCurrentUser();
  let _render;
  if (user && user?._id) {
    _render = (props) => {
      return <Component {...props} />;
    };
  } else {
    _render = (props) => {
      return <Redirect to="/SignIn" />;
    };
  }
  return <Route {...rest} render={(props) => _render(props)} />;
}

var hist = createBrowserHistory();
createRoot(document.querySelector("#root")).render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />

    <Router history={hist}>
      <App>
        <Switch>
          <Route exact path="/SignIn" component={Login} />
          <Route path="/SignIn/UserNotValidated" component={UserNotValidated} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/ForgotPassword/Verify" component={Verify} />
          <PrivateRoute
            path="/ForgotPassword/NewPassword"
            component={NewPassword}
          />
          <Route
            path="/ForgotPassword/ResendCode"
            component={ForgotPasswordResendCode}
          />
          <PrivateRoute
            path="/ForgotPassword/ResultMessageScreen"
            component={ResultMessageScreen}
          />
          <Route exact path="/ReceiptByEmail" component={ReceiptByEmail} />
          <Route path="/ReceiptByEmail/ReceiptSent" component={ReceiptSent} />
          <Route exact path="/SignUp" component={SignUp} />
          <Route
            path="/SignUp/AccountValidation"
            component={AccountValidation}
          />
          <PrivateRoute path="/SignUp/AllSet" component={AllSet} />
          <Route path="/SignUp/ResendCode" component={ResendCode} />
          <PrivateRoute exact path="/Purchases" component={Purchases} />
          <PrivateRoute
            path="/Purchases/PurchasesDetail"
            component={PurchasesDetail}
          />
          <PrivateRoute path="/CurrentPassword" component={CurrentPassword} />
          <Route exact path="/error" component={ErrorPage} />
          <Route path="/TermsAndConditions" component={TermsAndConditions} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <PrivateRoute path="/" component={Purchases} />
        </Switch>
      </App>
    </Router>
  </ThemeProvider>
);