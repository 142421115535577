
const monthShortNames = {
  en: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  fr: [
    "Janv",
    "Févr",
    "Mars",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aout",
    "Sept",
    "Oct",
    "Nov",
    "Déc",
  ],
  pt: [
    "Jan",
    "Feb",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  nl: [
    "jan",
    "febr",
    "mrt",
    "apr",
    "mei",
    "juni",
    "juli",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ],
};

export function getFormattedDateMonthYear(date, lang){
    const newDate = new Date(date);
    return (
      monthShortNames[lang][newDate.getMonth()] + ' ' + newDate.getFullYear()
    );
}


export function getFormattedDateDayMonthHour(date, lang) {
  const newDate = new Date(date);
  return (
    newDate.getDate() + " " +
    monthShortNames[lang][newDate.getMonth()] +
    " - " +
    ("0" + newDate.getHours()).slice(-2) +
    ":" +
    (newDate.getMinutes() < 10 ? "0" : "") +
    newDate.getMinutes()
  );
} 

export function getFormattedDateHourDayMonthYear(date){
  const newDate = new Date(date);
  return (
    ("0" + newDate.getHours()).slice(-2) +
    ":" +
    (newDate.getMinutes() < 10 ? "0" : "") +
    newDate.getMinutes() +
    " - " +
    newDate.getDate() +
    "/" +
    (newDate.getMonth() + 1) +
    "/" +
    newDate.getFullYear()
  );
}