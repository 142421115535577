import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  StyleSheet,
  Text,
  Pressable,
} from "react-native-web";

import DefaultTheme from "../../themes";
import { ReactComponent as Password } from "../../assets/icons/password.svg";
import { ReactComponent as Password2 } from "../../assets/icons/password2.svg";
import Button from "../../components/Button";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import AppLayout from "../../appLayout/AppLayout";
import AuthService from "../../services/auth-service";
import Modal from "../../components/Modal";
import { useHistory } from "react-router-dom";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import SmallParagraph from "../../components/UI/SmallParagraph";
import CustomTextInput from "../../components/UI/CustomTextInput";
import Paragraph from "../../components/UI/Paragraph";
import Switch from "../../components/Switch";
import {
  noResponseParams,
  notOkPageParams,
} from "../../components/Helpers/ResultMessagesHelper";

export default function SignUp({ navigation, route }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const filledEmail = history.location?.email;
  // const [name, onChangeName] = useState("");
  const [email, onChangeEmail] = useState(filledEmail || "");
  const [password, onChangePassword] = useState("");
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [checked, setChecked] = useState(false);

  const [showEmailInvalidMsg, setShowEmailInvalidMsg] = useState(false);
  const [showEmailRequiredMsg, setShowEmailRequiredMsg] = useState(false);
  const [showEmailDuplicatedMsg, setShowEmailDuplicatedMsg] = useState(false);
  const [showPasswordInvalidMsg, setShowPasswordInvalidMsg] = useState(false);
  const [showPasswordRequiredMsg, setShowPasswordRequiredMsg] = useState(false);
  const [showConditionsRequiredMsg, setShowConditionsRequiredMsg] = useState(false);
  const [showEmailPhoneDuplicatedMsg, setShowEmailPhoneDuplicatedMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationChecked, setNotificationChecked] = useState(true);

  const handleChange = (event) => {
    setNotificationChecked(event.target.checked);
  };

  const [modalDuplicatedUser, setModalDuplicatedUser] = useState(false);

  const signupButtons = [
    {
      title: t("buttons:logIn"),
      type: "PRIMARY",
      onPress: () => {
        history.push({
          pathname: "/SignIn",
          email: showEmailPhoneDuplicatedMsg ? "" : email,
        });
      },
    },
    {
      title: t("buttons:reviewAccountInfo"),
      type: "LINK",
      onPress: () => {
        setModalDuplicatedUser(false);
      },
    },
  ];

  const EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const PASSWORD_REGEX = new RegExp(
    /^((?!.*[\s])(?=.*?[a-z])(?=.*?[^a-zA-Z]).{8,})/
  );

  const emailIsValid = email.trim().length > 0 && EMAIL_REGEX.test(email);
  const passwordIsValid =
    password.trim().length > 0 && PASSWORD_REGEX.test(password);
  const conditionsIsValid = checked === true;

  function onSubmitValidation() {
    setLoading(true);
    if (!emailIsValid || !passwordIsValid || !conditionsIsValid) {
      !emailIsValid
        ? setShowEmailRequiredMsg(true)
        : setShowEmailRequiredMsg(false);
      !passwordIsValid
        ? setShowPasswordRequiredMsg(true)
        : setShowPasswordRequiredMsg(false);
      !conditionsIsValid ? setShowConditionsRequiredMsg(true) : setShowConditionsRequiredMsg(false);
      setLoading(false);
      return false;
    }
    setShowEmailRequiredMsg(false);
    setShowEmailInvalidMsg(false);
    setShowPasswordRequiredMsg(false);
    setShowConditionsRequiredMsg(false);
    handleRegister();
    setLoading(false);
  }

  const handleRegister = async (e) => {
    const res = await AuthService.register({
      password,
      email,
      username: email,
      locale: i18n.language,
      role: "user",
    });
    setLoading(true);
    if (res.success) {
      history.push({
        pathname: "/SignUp/AccountValidation",
        email: email,
        id: res.result,
        notifications: notificationChecked,
        card: history.location?.card,
        purchase: history.location?.purchase,
      });
    } else if (res.errorMessage.duplicateUser && res.errorMessage.email) {
      setShowEmailPhoneDuplicatedMsg(false);
      setShowEmailDuplicatedMsg(true);
      setModalDuplicatedUser(true);
    } else if (res.errorMessage.duplicateUser) {
      setShowEmailDuplicatedMsg(true);
      setShowEmailPhoneDuplicatedMsg(true);
      setModalDuplicatedUser(true);
    } else if (res.errorMessage.noResponse) {
      history.push("/error", {
        params: noResponseParams,
      });
    } else {
      history.push("/error", {
        params: notOkPageParams(
          res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater")
        ),
      });
    }
    setLoading(false);
  };

  console.log(showPasswordInvalidMsg)
  return (
    <>
      <AppLayout
        headerLabel={t("common:headerSignUp")}
        headerBack={true}
        route={route}
        navigation={navigation}
        style={{
          backgroundColor: "white",
          height: "auto",
          flex: 1,
          minHeight: "100vh",
        }}
      >
        <View style={{ display: "flex", height: "100%", paddingTop: "5%" }}>
          <Paragraph>
            {" "}
            Did you know that you can access to all of your purchases’
            history?{" "}
            Please register bellow to grant your receipts access{" "}
          </Paragraph>
          <View
            style={{
              marginTop: "10vh",
              marginBottom:
                showEmailRequiredMsg ||
                  showEmailInvalidMsg ||
                  showEmailDuplicatedMsg
                  ? 24.4
                  : 52,
            }}
          >
            <CustomTextInput
              onChangeText={onChangeEmail}
              onEndEditing={() => {
                if (email.trim().length > 0 && !EMAIL_REGEX.test(email)) {
                  setShowEmailInvalidMsg(true);
                } else {
                  setShowEmailInvalidMsg(false);
                  setShowEmailRequiredMsg(false);
                }
              }}
              value={email}
              placeholder={t("authentication:emailAddress") + "*"}
              textContentType="emailAddress"
              inputMode="email"
              keyboardType="email-address"
              autoCapitalize="none"
              autoComplete="email"
            />
            {(showEmailRequiredMsg ||
              showEmailInvalidMsg ||
              showEmailDuplicatedMsg) && (
                <SmallParagraph>
                  {showEmailRequiredMsg && (
                    <Text style={{ color: DefaultTheme.COLORS.error }}>
                      {t("errors:required")}
                    </Text>
                  )}
                  {showEmailInvalidMsg && (
                    <Text style={{ color: DefaultTheme.COLORS.error }}>
                      {t("errors:emailInvalid")}
                    </Text>
                  )}
                  {showEmailDuplicatedMsg && (
                    <Text style={{ color: DefaultTheme.COLORS.error }}>
                      {t("errors:emailDuplicated")}
                    </Text>
                  )}
                </SmallParagraph>
              )}
          </View>
          <div style={styles.passwordContainer}>
            <CustomTextInput
              onChangeText={onChangePassword}
              value={password}
              placeholder={t("authentication:password") + "*"}
              textContentType="newPassword"
              passwordRules="minlength: 8; required: lower; required: digit; required: [#];"
              secureTextEntry={secureTextEntry}
              style={styles.passwordInput}
              noBorderBottom={true}
              onEndEditing={() => {
                if (!passwordIsValid) {
                  setShowPasswordInvalidMsg(true);
                } else {
                  setShowPasswordInvalidMsg(false);
                  setShowPasswordRequiredMsg(false);
                }
              }}
            />
            <Pressable
              style={{
                display: "flex",
                justifyContent: "center",
                width: "8%",
              }}
              onPress={() => {
                setSecureTextEntry(secureTextEntry ? false : true);
              }}
            >
              {secureTextEntry ? (
                <Password style={{ alignSelf: "center" }} />
              ) : (
                <Password2 style={{ alignSelf: "center" }} />
              )}
            </Pressable>
          </div>
          <SmallParagraph
            style={[
              { fontSize: 10 },
              showPasswordInvalidMsg && { color: DefaultTheme.COLORS.error }]
            }
          >
            {showPasswordRequiredMsg && (
              <Text style={{ color: DefaultTheme.COLORS.error, fontSize: 12 }}>
                {t("errors:required")}
              </Text>
            )}
            {
              "Password must have at least 8 characters, at least 1 lowercase letter, 1 number and 1 symbol."
            }
          </SmallParagraph>
          <View style={styles.toggle_box}>
            <SmallParagraph
              style={{
                paddingBlock: "6px",
                paddingLeft: "18px",
                paddingRight: "24px",
                color: DefaultTheme.COLORS.color4,
                margin: 0,
              }}
            >
              Automatically send emails with the purchases detail.
            </SmallParagraph>
            <Switch
              checked={notificationChecked}
              defaultChecked={notificationChecked}
              onChange={handleChange}
            />
          </View>

          <BottomBtnContainer>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Checkbox
                icon={<Icon.FiCheck size={14} stroke-width={3} />}
                name="my-input"
                checked={false}
                borderColor={DefaultTheme.COLORS.grey2}
                style={{ cursor: "pointer", borderWidth: "1px" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                onChange={() => {
                  setChecked(!checked);
                  setShowConditionsRequiredMsg(checked);
                }}
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",

                }}
              >
                <SmallParagraph style={{
                  marginTop: 0, display: "flex",
                  flexDirection: "column"
                }}>
                  {showConditionsRequiredMsg && (
                    <Text style={{ color: DefaultTheme.COLORS.error }}>
                      {t("errors:required")}
                    </Text>
                  )}
                  {t("authentication:checkTermsAndPrivacy")}

                </SmallParagraph>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Pressable
                    onPress={() => window.open("/TermsAndConditions", "_blank")}
                  >
                    <SmallParagraph
                      style={{
                        color: DefaultTheme.COLORS.color5,
                        fontFamily: DefaultTheme.FONTS.Bold,
                        marginTop: 0,
                        textDecoration: "underline",
                      }}
                    >
                      {t("authentication:termsConditions")}
                    </SmallParagraph>
                  </Pressable>
                  <SmallParagraph style={{ marginTop: 0 }}>
                    {" " + t("authentication:and") + " "}
                  </SmallParagraph>
                  <Pressable
                    onPress={() => window.open("/PrivacyPolicy", "_blank")}
                  >
                    <SmallParagraph
                      style={{
                        color: DefaultTheme.COLORS.color5,
                        fontFamily: DefaultTheme.FONTS.Bold,
                        marginTop: 0,
                        textDecoration: "underline",
                      }}
                    >
                      {t("authentication:privacyPolicy")}
                    </SmallParagraph>
                  </Pressable>
                </View>
              </View>
            </View>
            <Button
              text={"Register Now"}
              onPress={onSubmitValidation}
              type="PRIMARY"
              loading={loading}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 24,
              }}
            >
              <Button
                text={"I have already an account"}
                onPress={() => {
                  history.push({
                    pathname: "/SignIn",
                    email: email,
                  });
                }}
                type="LINK"
              />
            </View>
          </BottomBtnContainer>
        </View>
        <Modal
          modalVisible={modalDuplicatedUser}
          setModalVisible={setModalDuplicatedUser}
          title={t("authentication:modalDuplicatedUserTitle")}
          subTitle={
            t("authentication:modalDuplicatedUserText01") +
            " " +
            email +
            " " +
            t("authentication:modalDuplicatedUserText02")
          }
          buttons={signupButtons}
        />
      </AppLayout>
    </>
  );
}

const styles = StyleSheet.create({
  uncheckedIcon: {
    color: DefaultTheme.COLORS.grey2,
  },
  uncheckedIcon_required: {
    color: DefaultTheme.COLORS.error,
  },
  passwordContainer: {
    fontFamily: DefaultTheme.FONTS.Regular,
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderColor: DefaultTheme.COLORS.grey5,
  },
  passwordInput: {
    overflow: "hidden",
    width: "90%",
    fontSize: 16,
  },
  toggle_box: {
    marginTop: 32,
    marginBottom: 42,
    borderColor: DefaultTheme.COLORS.color1,
    backgroundColor: DefaultTheme.COLORS.color1,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "10px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    marginBlock: "6px",
    justifyContent: "space-between",
    minHeigth: "50px",
  },
});
